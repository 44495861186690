import { Dialogs } from '@/types/enum';
import type { PendingAction } from '@/types/project';
import { getServiceNames } from '~/data/services';

export const useActionStore = defineComposableStore('action', () => {
  const initialized = ref(false);

  const pendingActions = computed<Map<string, PendingAction[]>>(() => {
    const { membershipApplicationsForManager } = useMemberStore();
    const { projects } = useProjectStore();
    const { pendingTerms } = useServiceProfileStore();

    if (!projects.value.length) return new Map();

    const serviceNames = getServiceNames();

    const membershipActions = membershipApplicationsForManager.value
      .reduce((actions, application) => {
        const project = projects.value.find(
          (p) => p.CSCPrjNum === application.project_id,
        );

        if (!project || project.isClosed) return actions;

        const existingAction = actions.find(
          (action) => action.projectNumber === application.project_id,
        );

        if (!existingAction) {
          actions.push({
            projectNumber: application.project_id,
            title: project?.CSCPrjTitle || '',
            subtitle: '',
            type: 'info',
            id: `${application.project_id}--membership`,
            count: 1,
          });

          return actions;
        }

        existingAction.count! += 1;

        return actions;
      }, [] as PendingAction[])
      .map((action) => ({
        ...action,
        subtitle:
          action.count! > 1
            ? `Project has <span class="bg-link text-white rounded-full px-1 text-xs">${action.count}</span> pending membership applications`
            : 'Project has a pending membership application',
      }));

    return (
      projects.value.reduce((messages, project) => {
        if (project.isClosed) return messages;

        const message = {
          projectNumber: project.CSCPrjNum,
          title: project.CSCPrjTitle,
        };

        if (
          project.isProjectManager &&
          project.isClosingMessage &&
          project.projectType !== 'Student'
        ) {
          messages.push({
            ...message,
            id: `${project.CSCPrjNum}--is-closing`,
            type: 'warning',
            subtitle: project.isClosingMessage,
          });
        }

        if (project.isProjectManager && project.hasBelowZeroBUs) {
          messages.push({
            ...message,
            id: `${project.CSCPrjNum}--out-of-bu`,
            type: 'error',
            subtitle: 'Project is out of Billing Units',
          });
        }

        if (pendingTerms.value[project.CSCPrjNum]) {
          messages.push({
            ...message,
            id: `${project.CSCPrjNum}--pending-terms`,
            type: 'warning',
            subtitle: `Unaccepted terms of use for ${formatList(
              pendingTerms.value[project.CSCPrjNum].map(
                (id) => serviceNames[id] || id,
              ),
            )}`,
          });
        }

        return messages;
      }, membershipActions) || []
    ).reduce((actions, action) => {
      if (!actions.has(action.projectNumber)) {
        actions.set(action.projectNumber, [] as PendingAction[]);
      }

      actions.get(action.projectNumber)!.push(action);

      return actions;
    }, new Map<string, PendingAction[]>());
  });

  const { accountExtensionRequests, profile, daysUntilLoginExpiration } =
    useProfileStore();

  const isAccountExpiring = computed(() => {
    if (
      !profile.value.cn ||
      profile.value.CSCUserType !== 'academic_international'
    ) {
      return false;
    }

    return daysUntilLoginExpiration.value <= 31;
  });

  const amountOfActions = computed(
    () =>
      pendingActions.value.size +
      accountExtensionRequests.value.length +
      (isAccountExpiring.value ? 1 : 0),
  );
  const route = useRoute();

  watch(
    () => route.name,
    (routeName) => {
      const routesWithoutPendingActions = ['Profile', 'OIDCLogin', 'Login'];

      if (
        !amountOfActions.value ||
        routesWithoutPendingActions.includes(routeName as string) ||
        initialized.value
      )
        return;

      const { addDialog } = useDialogStore();

      initialized.value = true;

      addDialog(Dialogs.PendingActions);
    },
    { immediate: true },
  );

  watch(
    amountOfActions,
    (actions) => {
      if (!actions || initialized.value) return;

      const { addDialog } = useDialogStore();

      const routesWithoutPendingActions = ['Profile', 'OIDCLogin', 'Login'];

      if (routesWithoutPendingActions.includes(route.name as string)) return;

      initialized.value = true;

      addDialog(Dialogs.PendingActions);
    },
    { immediate: true },
  );

  return {
    pendingActions,
    amountOfActions,
    isAccountExpiring,
  };
});
